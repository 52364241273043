import React from "react";
import { Link } from "react-router-dom";
import { employerUrls, jobseekerUrls } from "../helper/urls";

const FooterOne = () => {
  return (
    <footer
      className="footer-wrapper footer-layout1"
      style={{ backgroundImage: "url(assets/img/bg/footer-bg1-1.png)" }}
    >
      <div className="container">
        <div className="widget-area">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-5">
              <div className="widget footer-widget widget-about">
                <div className="about-logo">
                  <Link to="/">
                    <img src="assets/img/logo-white.svg" alt="TICH" />
                  </Link>
                </div>
                <p className="footer-text mb-30">
                  Connecting African workforce to the world
                </p>
                <div className="social-btn style3">
                <Link to="https://www.facebook.com/CVstudio.africa/" target="_blank" tabIndex={-1}>
                  <i className="fab fa-facebook-f" />
                </Link>
                <Link to="https://www.linkedin.com/company/cvstudio-africa/" target="_blank" tabIndex={-1}>
                  <i className="fab fa-linkedin-in" />
                </Link>
                <Link to="https://twitter.com/cvs_africa/" target="_blank" tabIndex={-1}>
                  <i className="fab fa-twitter" />
                </Link>
                <Link to="https://www.instagram.com/cvstudioafrica/" target="_blank" tabIndex={-1}>
                  <i className="fab fa-instagram" />
                </Link>
                <Link to="https://podcasters.spotify.com/pod/show/bfound-series-by-cvstudio" target="_blank" tabIndex={-1}>
                  <i className="fab fa-spotify" />
                </Link>
                <Link to="https://www.tiktok.com/@cvstudio.africa" target="_blank" tabIndex={-1}>
                  <i className="fab fa-tiktok" />
                </Link>
                <Link to="https://www.youtube.com/@cvstudioafrica/" target="_blank" tabIndex={-1}>
                    <i className="fab fa-youtube" />
                </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Useful Links</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to={employerUrls.devUrl} target="_blank">Join as Employer</Link>
                    </li>
                    <li>
                      <Link to={jobseekerUrls.devUrl} target="_blank">Join as Jobseeker</Link>
                    </li>
                    {/* <li>
                      <Link to="#">F.A.Q's</Link>
                    </li> */}
                    <li>
                      <Link to="/service">Our services</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="widget footer-widget me-xl-3">
                <h3 className="widget_title">Contact</h3>
                <div className="widget-contact2">
                  <div className="widget-contact-grid">
                    <i className="fas fa-phone-alt" />
                    <div className="contact-grid-details">
                      <h6>
                        <a href="tel:+254114039991">(+254) - 0114039991</a>
                      </h6>
                    </div>
                  </div>
                  <div className="widget-contact-grid">
                    <i className="fas fa-envelope" />
                    <div className="contact-grid-details">
                      <h6>
                        <a href="mailto:admin@tich.work">admin@tich.work</a>
                      </h6>
                    </div>
                  </div>
                  <div className="widget-contact-grid">
                    <i className="fas fa-map-marker-alt" />
                    <div className="contact-grid-details">
                      <h6>
                        Nairobi, Kenya
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright-wrap">
          <div className="row gy-3 justify-content-lg-between justify-content-center">
            <div className="col-auto align-self-center">
              <p className="copyright-text text-center">
                © <Link to="#">Tich </Link> {new Date().getFullYear()} | All Rights Reserved
              </p>
            </div>
            <div className="col-auto">
              <div className="footer-links">
                <Link to="/privacy-policy">Privacy policy</Link>
                <Link to="/terms-of-use">Terms of usage</Link>
                <Link to="/contact">Help</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
