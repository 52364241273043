import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { sendMessage } from '../models/contact';

const MySwal = withReactContent(Swal);

const alertContent = (error) => {
  MySwal.fire({
    title: error ? "Sorry" : "Congratulations!",
    text: error
      ? "An error occurred while trying to send your message"
      : "Your message was successfully sent and will get back to you soon",
    icon: error ? "error" : "success",
    timer: 5000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

// Form initial state
const INITIAL_STATE = {
  email: "",
  text: "",
};

const ContactInner = () => {
  const [contact, setContact] = useState(INITIAL_STATE);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { email, text } = contact;
      const payload = {
        email: email,
        message: text,
        platform: "MAIN_WEBSITE",
      };

      sendMessage(payload)
        .then(() => {
          setContact(INITIAL_STATE);
          alertContent();
        })
        .catch((error) => {
          alertContent(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

  return (
    <>
      <div className="map-sec">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255282.43284881077!2d36.720376199756714!3d-1.3021282423906704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11655c311541%3A0x9dd769ac1c10b897!2sNairobi%20County!5e0!3m2!1sen!2ske!4v1725356204753!5m2!1sen!2ske"
          allowFullScreen=""
          loading="lazy"
          title='TICH'
        />
      </div>

      <div className="contact-area space">
        <div className="container">
          <div className="contact-page-wrap bg-theme">
            <div className="row gy-40 align-items-center">
              <div className="col-lg-4 col-md-6">
                <div className="contact-info">
                  <div className="contact-info_icon">
                    <i className="fas fa-phone-alt" />
                  </div>
                  <div className="contact-info_details">
                    <h6 className="contact-info_title">Tel</h6>
                    <p className="contact-info_text">
                      <span >(+254) - 0114039991</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-info">
                  <div className="contact-info_icon">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <div className="contact-info_details">
                    <h6 className="contact-info_title">Location</h6>
                    <p className="contact-info_text">
                      Nairobi Kenya
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-info">
                  <div className="contact-info_icon">
                    <i className="fas fa-envelope" />
                  </div>
                  <div className="contact-info_details">
                    <h6 className="contact-info_title">Email</h6>
                    <p className="contact-info_text">
                      <span>admin@tich.work</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="space-bottom">
        <div className="container">
          <div className="row gy-40 justify-content-between">
            <div className="col-lg-4">
              <div className="title-area mb-0">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  Contact Us
                </span>
                <h2 className="sec-title style2">Get In Touch</h2>
                <p className="mb-40">
                  Send us a question, comment, inquiry or query
                </p>
                <div className="social-btn style4">
                  <Link to="https://www.facebook.com/CVstudio.africa/" target="_blank" tabIndex={-1}>
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link to="https://www.linkedin.com/company/cvstudio-africa/" target="_blank" tabIndex={-1}>
                    <i className="fab fa-linkedin-in" />
                  </Link>
                  <Link to="https://twitter.com/cvs_africa/" target="_blank" tabIndex={-1}>
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link to="https://www.instagram.com/cvstudioafrica/" target="_blank" tabIndex={-1}>
                    <i className="fab fa-instagram" />
                  </Link>
                  <Link to="https://podcasters.spotify.com/pod/show/bfound-series-by-cvstudio" target="_blank" tabIndex={-1}>
                    <i className="fab fa-spotify" />
                  </Link>
                  <Link to="https://www.tiktok.com/@cvstudio.africa" target="_blank" tabIndex={-1}>
                    <i className="fab fa-tiktok" />
                  </Link>
                  <Link to="https://www.youtube.com/@cvstudioafrica/" target="_blank" tabIndex={-1}>
                    <i className="fab fa-youtube" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-8">
            <form onSubmit={handleSubmit}>
              <div className="contact-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="email"
                      name="email"
                      value={contact.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      className="form-control style-border"
                      required
                    />
                  </div>
                  <div className="col-12 form-group">
                    <textarea
                      name="text"
                      cols="30"
                      rows="6"
                      value={contact.text}
                      onChange={handleChange}
                      required
                      placeholder="Write your message..."
                      className="form-control style-border"
                    />
                  </div>
                  <div className="col-12 form-group mb-0">
                    <button type="submit" className="global-btn w-100">
                      Send Message
                      <img src="assets/img/icon/right-icon.svg" alt="TICH" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default ContactInner