export const employerUrls = {
  devUrl: "https://employer-dev.tich.work",
  qaUrl: "https://employer-qa.tich.work",
  prodUrl: "https://employer.tich.work"
}

export const jobseekerUrls = {
  devUrl: "https://jobseeker-dev.tich.work",
  qaUrl: "https://jobseeker-qa.tich.work",
  prodUrl: "https://jobseeker.tich.work"
}

export const apiUrls = {
  devUrl: "https://api-dev.tich.work/v1",
  qaUrl: "https://api-qa.tich.work/v1",
  prodUrl: "https://api.tich.work/v1"
}

export const NODE_ENV = {
  DEV: "dev",
  QA: "qa",
  PROD: "prod",
}