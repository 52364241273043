import { apiUrls } from "./urls";

export default {
  getApi() {
    return {
      DASHBOARD: {
        GET_STATS: `${apiUrls.devUrl}/dashboards/getAdminDashboardStats`
      },
      LOOKUPS: {
        LOOKUP_PARTNERS: `${apiUrls.devUrl}/lookUps/getPartners`,
        LOOKUP_FEEDBACK: `${apiUrls.devUrl}/lookUps/getFeedbacks`,
      },
      CONTACT: {
        SEND_MSG: `${apiUrls.devUrl}/contacts/addContact`,
      }
    };
  },
};
