import React from 'react'

const FeatureOne = () => {
    return (
        <section className='bg-white'>
            <div className="feature-area-4 space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="title-area text-center">
                                <h2 className="sec-title style2 text-dark">
                                  What we offer
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="feature-wrap4">
                      <div className="feature-bg-line">
                        <img src="assets/img/feature/feature_line.png" alt="img" />
                      </div>
                      <div className="row gy-80 justify-content-center justify-content-lg-between align-items-center">
                        <div className="col-lg-5 order-lg-2">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/digital-lending.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-lg-1">
                          <div className="feature-content">
                            <h4 className="feature-content-title text-dark mb-20">
                              Digital lending
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              Gig-workforce access to lending
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-6 order-3">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/employer-solutions.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-4">
                          <div className="feature-content">
                            <h4 className="feature-content-title mb-20 text-dark">
                              Employer solutions
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              Hire, manage, pay employees on one platform
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="feature-wrap4">
                      <div className="feature-bg-line">
                        <img src="assets/img/feature/feature_line.png" alt="img" />
                      </div>
                      <div className="row gy-80 justify-content-center justify-content-lg-between align-items-center">
                        <div className="col-lg-5 order-lg-2">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/Payments.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-lg-1">
                          <div className="feature-content">
                            <h4 className="feature-content-title text-dark mb-20">
                              Payments
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              Integrated e-wallet that enables gig-workers receive global payments.
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-6 order-3">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/gig-cv.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-4">
                          <div className="feature-content">
                            <h4 className="feature-content-title mb-20 text-dark">
                              Gig CV
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              Consolidation of online reputation from various platforms on one document.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="feature-wrap4">
                      <div className="feature-bg-line">
                        <img src="assets/img/feature/feature_line.png" alt="img" />
                      </div>
                      <div className="row gy-80 justify-content-center justify-content-lg-between align-items-center">
                        <div className="col-lg-5 order-lg-2">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/api-integrations.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-lg-1">
                          <div className="feature-content">
                            <h4 className="feature-content-title text-dark mb-20">
                              Analytics
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              Real time insights on the world of work in Africa.
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-6 order-3">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/Skilling.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-4">
                          <div className="feature-content">
                            <h4 className="feature-content-title mb-20 text-dark">
                              Skilling
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              AI enabled on-demand curated training per user.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="feature-wrap4">
                      <div className="feature-bg-line">
                        <img src="assets/img/feature/feature_line.png" alt="img" />
                      </div>
                      <div className="row gy-80 justify-content-center justify-content-lg-between align-items-center">
                        <div className="col-lg-5 order-lg-2">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/api-integrations.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-lg-1">
                          <div className="feature-content">
                            <h4 className="feature-content-title text-dark mb-20">
                              Integration API's
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              Easy integration with 3rd party systems for real-time access to CVSTUDIO AFRICA ecosystem
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-6 order-3">
                          <div className="feature-thumb4-1">
                            <img src="assets/img/service/new.jpg" alt="img" />
                          </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 order-4">
                          <div className="feature-content">
                            <h4 className="feature-content-title mb-20 text-dark">
                              Something new is coming....
                            </h4>
                            <p className="feature-content-text mb-30 text-dark">
                              
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default FeatureOne