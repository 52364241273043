import React from "react";

const EventOne = () => {
  return (
    <div className="event-area-1 bg-smoke">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="event-wrapp">
              <div className="title-area">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  Values
                </span>
                <h2 className="sec-title style2">
                  Core values
                </h2>
              </div>
              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-1.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Empowerment</h4>
                  <p className="event-card-text">
                    We empower individuals to take control of their careers by providing them with 
                    the tools and resources needed to find the right job opportunities.
                  </p>
                </div>
              </div>

              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-2.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Inclusivity</h4>
                  <p className="event-card-text">
                    We embrace Inclusivity as a core value, aiming to connect individuals from diverse backgrounds, 
                    regions, and socio-economic circumstances with employment opportunities. Ensure that the platform 
                    is accessible to job seekers and employers across Africa, regardless of their location or resources
                  </p>
                </div>
              </div>

              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-3.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Adaptability</h4>
                  <p className="event-card-text">
                    Recognize the diversity of job markets across Africa and the need for adaptability. Tailor the platform 
                    to accommodate different languages, skill sets, and industries prevalent in various African regions, ensuring that it remains relevant and effective.
                  </p>
                </div>
              </div>


              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-1.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Community</h4>
                  <p className="event-card-text">
                    We believe in building a supportive community where users can network, learn, and grow professionally.
                  </p>
                </div>
              </div>

              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-2.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Collaboration</h4>
                  <p className="event-card-text">
                    We encourage collaboration among job seekers, employers, educational institutions, career coaches 
                    and other stakeholders such as government non-profits and other agencies  to create a holistic ecosystem that benefits all parties.
                  </p>
                </div>
              </div>

              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-3.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Ethical Conduct</h4>
                  <p className="event-card-text">
                    We adhere to ethical principles in all our business practices, respecting privacy, confidentiality, and fairness.
                  </p>
                </div>
              </div>

              <div className="event-card">
                <div className="icon">
                  <img src="assets/img/icon/event-icon_1-1.svg" alt="img" />
                </div>
                <div className="event-card-details">
                  <h4 className="event-card-title">Sustainability</h4>
                  <p className="event-card-text">
                    We consider the long-term impact of our actions on the job market and strive to contribute positively to the economic and social well-being of individuals and communities.
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="event-image">
              <div className="event-thumb-1">
                <img src="assets/img/normal/about-bg.svg" alt="img" />
              </div>
              <div className="event-shape">
                <img src="assets/img/normal/event-shape.png" alt="CVSTUDIO" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventOne;
