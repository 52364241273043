import React from 'react'
import { Link } from 'react-router-dom'
import { jobseekerUrls } from '../helper/urls'

const PrivacyPolicyContent = () => {
  return (
    <div className="portfolio-deatils-area space-bottom">
      <div className="container">
          <p className="mt-20">
            This statement provides information on how Tich Limited (hereinafter “the Company” approaches processing your personal data. Our focus and commitment to respecting your privacy and safeguarding your personal data remain stronger than ever.
          </p>
        <div className="row gx-30">
          <h3 className="page-title mt-20">1.1 Terms used in this Statement</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                <b>Personal Data</b> Information that directly or indirectly identifies or says something about you is referred to as personal data. Examples include your name and address, and information such as your income. Information relating to a sole trader, commercial partnership, or professional partnership is also considered personal data.
              </p>

              <p className="mb-20">
                <b>Processing Data</b> Processing means anything that can be done with personal data. This includes the collection, storage, use, transfer, and removal of data.
              </p>

              <p className="mb-20">
                <b>Whose Personal Data does the Company process?</b> We process personal data if we have, want to have, or have had a business relationship with you, or if we have had contact with you.
                The people whose personal data we process include:
                <ul>
                  <li>Existing Company clients both domestic and foreigners</li>
                  <li>Our employees</li>
                  <li>Potential Company customers</li>
                  <li>People who show an interest in the Company’s products and services are people who are connected in another way with a business or 
                    organization with which we have, want to have, or have had a business relationship, such as third-party participants</li>
                  <li>Security providers and guarantors</li>
                </ul>
              </p>
          </div>
        </div>

        <img src="assets/img/privacy-policy.png" alt="TICH" />

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.1 How does the Company collect your Personal Data?</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                We receive your personal data when you provide it to the Company, examples include when opening an account, your educational and employment history and CV data.
              </p>

              <p className="mb-20">
                We may also receive data from others, such as suppliers or other parties we work with, or because you have given another party consent to share data with us.
              </p>

              <p className="mb-20">
                We may also receive data from others, such as public sources like newspapers, public registers and websites.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.2 How long does the Company Retain your Data?</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                We do not keep your data for any longer than necessary to fulfill the purposes for which we collected the data. 
                We have adopted a data retention policy. This policy specifies how long we keep data. In general, we will keep 
                your data for seven years following the termination of the relevant agreement or the ending of your business 
                relationship with the Company, unless there is a legal obligation to preserve the data longer e.g. if the regulator 
                asks us to keep specific data for longer in the context of risk models. In some cases, we use shorter retention 
                periods.
              </p>

              <p className="mb-20">
                In specific situations, we may also keep data for longer than we are required by the retention period fixed by us. 
                We will do this if, for example, the judicial authorities request for such information, or if you have submitted a 
                complaint, in which case the underlying data must be kept for longer. Once we no longer require the data for the purposes 
                described before, we may keep the data for archiving purposes, in the event of legal proceedings, or for historic or scientific 
                research purposes or statistical purposes.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.3 Processing of special categories of Data at the Company</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                We process special categories of personal data where this is permitted by law and consent is acquired, 
                this may include the use of Bio-metrics, etc. If you give us consent to record special categories of personal 
                data relating to you, or you have made this information public yourself, we will only process the information 
                if this is necessary so that we can provide our services. If you have given us consent to record special 
                categories of personal data, you may withdraw that consent at any time. To do this, please contact our website 
                or contact information provided therein.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.4 Does the Company use Automated Individual Decision-making including Profiling?</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                We will only decide based solely on automated processing including profiling which produces legal effects concerning 
                you or significantly affects you, in case it is allowed by law and we have gained consent. We do not envisage that any 
                decisions will be taken about you that produce legal effects or significantly affects you.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.5 Who at the Company has access to your Data?</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                Within the Company your personal data can be accessed only by individuals who need to have 
                access owing to their role and for official business purposes. All these people are bound by a 
                duty of confidentiality.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.6 Does the Company use your Personal Data for any other purpose?</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                If we want to use the information for any purpose other than the purpose for which it was obtained, 
                we may do this if the two purposes are closely related. If there is not a sufficiently strong correlation 
                between the purpose for which we obtained the data and the new purpose, we will ask you to give your consent. 
                If you have given us consent, you may withdraw that consent at any time. To do this, please contact the company 
                through our official communication channels.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.7 Does the Company Transfer your data to other countries outside Kenya?</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                If we transfer your data to other parties outside Kenya, we take additional measures to protect your data. 
                The Company may leverage on technologies such as Cloud storage/computing, which may result in your data being 
                shared and stored in different jurisdictions. However, in such cases, the highest level of protection will always 
                be embedded to safeguard your data.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.8 Customer Data Rights at the Company</h3>
          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20 text-theme2">a) Right to Access</h4>
              <p className="mb-20">
                You may ask the company through our official communication channels to access all the information 
                that we have and process in relation to you. The data protection office shall respond to such requests 
                as stipulated by the law.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20 text-theme2">b) Right to be Forgotten</h4>
              <p className="mb-20">
                You may request that we erase data concerning yourself that we have captured, for example, 
                if you object to the processing of your personal data. Your interest must also be greater than 
                the Company’s interest in processing the data.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20 text-theme2">c) Right to Data Portability</h4>
              <p className="mb-20">
                You have the right to request the Company to supply you with data that you previously 
                provided to the Company in the context of a contract with us or with your consent, in a 
                structured, machine-readable format, or that we transfer such data to another party. If you 
                ask us to transfer data directly to another party, we can do this only if this is technically feasible.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20 text-theme2">d) Right to Rectification</h4>
              <p className="mb-20">
                If you believe your personal data has been processed incorrectly or incompletely, 
                you may request that we change or supplement the data.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20 text-theme2">e) Right to restriction of processing</h4>
              <p className="mb-20">
                You may request that we restrict the personal data relating to you that we process. This means that we will process fewer personal data relating to you.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20 text-theme2">f) Right to object to Direct Marketing</h4>
              <p className="mb-20">
                You have the right to request the Company to stop sending marketing communication. You can place your request to us at the contact information below.
              </p>
          </div>
        </div>

        <div className="row gx-30 mt-20">
          <h3 className="page-title mt-20">2.9 Whom to contact to place a complaint or log in an inquiry regarding Personal Data</h3>
          <div className="col-12 order-lg-3">
              <p className="mb-20">
                If you have any questions concerning the processing of personal data by us, please contact:
              </p>

              <div>
                <span>Email: </span>
                <a href="mailto:admin@tich.work" className="text-theme2">admin@tich.work</a>
              </div>

              <div>
                <span>Website: </span>
                <a href="/" className="text-theme2">Tich</a>
              </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default PrivacyPolicyContent