import React from 'react'

const GoalArea = () => {
    return (
        <div className="goal-area space">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="title-area">
                            <span className="sub-title">
                                <img src="assets/img/icon/title_left.svg" alt="shape" />
                                Our Purpose
                            </span>
                            <h2 className="sec-title style2">Tomorrow's African workforce, today's global platform</h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="goal-title-area">
                            <p className="">
                                To enable, facilitate and improve African people’s work opportunities, productivity and livelihoods whilst equipping them for the world of work via tech-enabled skilling.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="goal-tabs-wrapper">
                        <div className="nav nav-tabs goal-tabs-tabs" id="nav-tab" role="tablist">
                            <button
                                className="nav-link"
                                id="nav-step1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step1"
                                type="button"
                            >
                                Our Purpose
                            </button>

                            <button
                                className="nav-link"
                                id="nav-step2-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step2"
                                type="button"
                            >
                                Our vision
                            </button>

                            <button
                                className="nav-link active"
                                id="nav-step3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step3"
                                type="button"
                            >
                                Mission
                            </button>

                            <button
                                className="nav-link"
                                id="nav-step4-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step1"
                                type="button"
                            >
                                Our Values
                            </button>
                        </div>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade" id="nav-step1" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Our Purpose
                                            </h4>
                                            <p>
                                                To enable, facilitate and improve African people’s work opportunities, productivity and livelihoods whilst equipping them for the world of work via tech-enabled skilling.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step2" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Performance Enhancement Partners
                                            </h4>
                                            <p>Strategic Solutions Pro</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">Management Mastery Consultan</h4>
                                            <p>Profitability Maximizers</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Operational Excellence Solutions
                                            </h4>
                                            <p>Framer Designer &amp; Developer</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">Transformational Strategy</h4>
                                            <p>Efficiency Experts</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade active show" id="nav-step3" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Performance Enhancement Partners
                                            </h4>
                                            <p>Strategic Solutions Pro</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">Management Mastery Consultan</h4>
                                            <p>Profitability Maximizers</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Operational Excellence Solutions
                                            </h4>
                                            <p>Framer Designer &amp; Developer</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">Transformational Strategy</h4>
                                            <p>Efficiency Experts</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step4" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Performance Enhancement Partners
                                            </h4>
                                            <p>Strategic Solutions Pro</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">Management Mastery Consultan</h4>
                                            <p>Profitability Maximizers</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">
                                                Operational Excellence Solutions
                                            </h4>
                                            <p>Framer Designer &amp; Developer</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="CVSTUDIO" />
                                        <div className="goal-content">
                                            <h4 className="box-title">Transformational Strategy</h4>
                                            <p>Efficiency Experts</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Success Accelerators
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Started politician Club
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>

    )
}

export default GoalArea