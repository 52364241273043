import React from 'react'
import { Link } from 'react-router-dom'
import { jobseekerUrls } from '../helper/urls'

const TermsOfUseContent = () => {
  return (
    <div className="portfolio-deatils-area space-bottom">
      <div className="container">
        <div className="row gx-30">
          <h3 className="page-title mt-30">1. Introduction</h3>
          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">1.1 Agreement</h4>
              <p className="mb-20">
                When you use our Services you agree to all of these terms. Your use of our Services is also 
                subject to our Cookie Policy and our Privacy Policy, which covers how we collect, use, share, and 
                store your personal information.
              </p>

              <p className="mb-20">
                You agree that by clicking <Link to={jobseekerUrls.devUrl} className="link-btn">Sign in</Link> or similar, 
                registering, accessing or using our services (described below), you are agreeing to enter into a legally binding 
                Agreement with Tich (even if you are using our Services on behalf of a company). If you do not agree to this 
                Agreement (“Agreement ” or “User Agreement”), do not click “Sign Up” (or similar) and do not access or otherwise use 
                any of our Services. If you wish to terminate this Agreement , at any time you can do so by closing your account and no 
                longer accessing or using our Services.
              </p>

              <h5 className="text-theme2">Services</h5>
              <p className="mb-20">
                This Agreement applies to Tich, Tich branded apps, Tich gigs, and other Tich-related 
                sites, apps, communications and other services that state that they are offered under this Agreement (“Services”), including the 
                offsite collection of data for those Services, such as our ads and the and any plugins. Registered users of our Services are “Members” 
                and unregistered users are “Visitors”.
              </p>

              <h5 className="text-theme2">Tich</h5>
              <p className="mb-20">
                You are entering into this Agreement with Tich (also referred to as “we” and “us”).
                We use the term “Designated Countries” to refer to countries in the European Union (EU), European Economic Area (EEA), and Switzerland.
                Tich will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services.
              </p>

              <p className="mb-20">
                As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy 
                (which includes our Cookie Policy and other documents referenced in this Privacy Policy) and updates.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">1.2 Members and Visitors</h4>
              <p className="mb-20">
                When you register and join the Tich Services, you become a Member. If you have chosen not to register for 
                our Services, you may access certain features as a “Visitor.”
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">1.3 Change</h4>
              <p className="mb-20">
                We may modify this Agreement , our Privacy Policy and our Cookies Policy from time to time. If we make material changes to it,
                we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before 
                they become effective. We agree that changes cannot be retroactive. If you object to any changes, you may close your account. 
                Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting 
                to the updated terms as of their effective date.
              </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">2. Obligations</h3>
          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">2.1 Service Eligibility</h4>
              <p className="mb-20">
                The Services are not for use by anyone under the age of 16.
              </p>

              <p className="mb-20">
                To use the Services, you agree that:
                <ol>
                  <li>You must be the "Minimum Age" (described below) or older.</li>
                  <li>You will only have one Tich account, which must be in your real name.</li>
                  <li>You are not already restricted by Tich from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 18.</li>
                </ol> 
              </p>

              <p className="mb-20">
                “Minimum Age” means 18 years old. However, if any local law requires that you must be older in order for Tich 
                to lawfully provide the Services to you without parental consent (including using of your personal data) then the Minimum 
                Age is such older age.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">2.2 Your Account</h4>
              <p className="mb-20">
                Members are account holders. You agree to:
                <ol>
                  <li>Use a strong password and keep it confidential.</li>
                  <li>Not transfer any part of your account (e.g., connections).</li>
                  <li>
                    Follow the law and our list of Dos and Don’ts and Professional Community Policies. You are responsible 
                    for anything that happens through your account unless you close it or report misuse.
                  </li>
                </ol> 
              </p>

              <p className="mb-20">
                As between you and others (including your employer), your account belongs to you. However, if the Services were 
                purchased by another party for you to use (e.g. an employer), the party paying for such Service has the right to 
                control access to and get reports on your use of such paid Service; however, they do not have rights to your personal account.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">2.3 Payment</h4>
              <p className="mb-20">
                If you buy any of our paid Services (“Premium Services”), you agree to pay us the applicable fees and taxes and to additional terms specific to the paid Services. Failure to pay these fees will result in the termination of your paid Services. Also, you agree that:
                <ul>
                  <li>Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).</li>
                  <li>We may store and continue billing your payment method (e.g. credit card, or mobile money number details) even after it has expired, to avoid interruptions in your Services and to use to pay other Services you may buy.</li>
                  <li>If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, cancel before the renewal date. Learn how to cancel or suspend your Premium Services.</li>
                  <li>All of your purchases of Services are subject to Tich’s refund policy.</li>
                  <li>We may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.</li>
                  <li>You can get a copy of your invoice through your Tich account settings under “My subscriptions”.</li>
                </ul> 
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">2.4 Notices and Messages</h4>
              <p className="mb-20">
                You agree that we will provide notices and messages to you in the following ways:

                <ol>
                  <li>Within the Service, or</li>
                  <li>Sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.</li>
                </ol> 
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">2.5 Sharing</h4>
              <p className="mb-20">
                Our Services allow messaging and sharing of information in many ways, such as your profile, 
                job postings, messages and in mails. Information and content that you share or post may be seen 
                by other Members, Visitors or others (including off of the Services). Where we have made settings 
                available, we will honor the choices you make about who can see content or information 
                (e.g., message content to your addressees, sharing content only to Tich employers, restricting your
                profile visibility from search engines, or opting not to notify others of your Tich profile update). 
                For job searching activities, we default to not notifying the public. So, if you apply for a job through our Service 
                or opt to signal that you are interested in a job, our default is to share it only with the job poster.
              </p>

              <p className="mb-20">
                We are not obligated to publish any information or content on our Service and can remove it with or without notice.
              </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">3. Rights and Limits</h3>
          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">3.1 Your License to Tich</h4>
              <p className="mb-20">
                You own all of the content, feedback and personal information you provide to us, but you also grant us a non-exclusive license to it.
                We’ll honor the choices you make about who gets to see your information and content, including how it can be used for ads.
              </p>

              <p className="mb-20">
                As between you and Tich, you own the content and information that you submit or post to the Services, and you are only granting Tich and our affiliates the following non-exclusive license:
              </p>

              <p className="mb-20">
                A worldwide, transferable and sub-licensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:
                <ol>
                  <li>You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.</li>
                  <li>We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your engagement with the Services may be visible and included with ads, as noted in the Privacy Policy. If you use a Service feature, we may mention that with your name or photo to promote that feature within our Services, subject to your settings.</li>
                  <li>We will get your consent if we want to give others the right to publish your content beyond the Services. However, if you choose to share your post as "public, everyone or similar", we will enable a feature that allows other Members to embed that public post onto third-party services, and we enable search engines to make that public content findable through their services.</li>
                  <li>While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</li>
                  <li>Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others, including under the terms of a Creative Commons license.</li>
                </ol> 
              </p>

              <p className="mb-20">
                You and Tich agree that we may access, store, process and use any information and personal data that you provide in accordance with, the terms of the <Link to="/privacy-policy" className="link-btn">Privacy Policy</Link> and your choices.
              </p>

              <p className="mb-20">
                By submitting suggestions or other feedback regarding our Services to Tich, you agree that Tich can use and share (but does not have to) such feedback for any purpose without compensation to you.
              </p>

              <p className="mb-20">
                You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. Tich may be required by law to remove certain information or content in certain countries.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">3.2 Service Availability</h4>
              <p className="mb-20">
                We may change, suspend or discontinue any of our Services. We may also 
                modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.
              </p>

              <p className="mb-20">
                We don’t promise to store or keep showing any information and content that you’ve posted. 
                Tich is not a storage service. You agree that we have no obligation to store, maintain or 
                provide you a copy of any content or information that you or others provide, except to the extent required by 
                applicable law and as noted in our <Link to="/privacy-policy" className="link-btn">Privacy Policy</Link>.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">3.3 Other Content, Sites and Apps</h4>
              <p className="mb-20">
                By using the Services, you may encounter content or information that might be inaccurate, incomplete, 
                delayed, misleading, illegal, offensive or otherwise harmful. Tich generally does not review 
                content provided by our Members or others. You agree that we are not responsible for others’ 
                (including other Members’) content or information. We cannot always prevent this misuse of our Services, 
                and you agree that we are not responsible for any such misuse. You also acknowledge the risk that you or 
                your organization may be mistakenly associated with content about others.
              </p>

              <p className="mb-20">
                Tich may help connect Members offering their services (career coaching, accounting, etc.) 
                with Members seeking services. Tich does not perform nor employ individuals to perform these 
                services. You must be at least 18 years of age to offer, perform or procure these services. You acknowledge that 
                Tich does not supervise, direct, control or monitor Members in the performance of these services and agree 
                that:
                <ol>
                  <li>Tich is not responsible for the offering, performance or procurement of these services,.</li>
                  <li>Tich does not endorse any particular Member’s offered services.</li>
                  <li>
                    Nothing shall create an employment, agency, or joint venture relationship between Tich and any 
                    Member offering services. If you are a Member offering services, 
                    you represent and warrant that you have all the required licenses.
                  </li>
                </ol>
              </p>


              <p className="mb-20">
                Similarly, Tich may help you register for and/or attend events organized by Members and connect with other 
                Members who are attendees at such events. You agree that:
                <ol>
                  <li>Tich is not responsible for the conduct of any of the Members or other attendees at such events, </li>
                  <li>Tich does not endorse any particular event listed on our Services,</li>
                  <li>Tich does not review and/or vet any of these events.</li>
                  <li>That you will adhere to these terms and conditions that apply to such events.</li>
                </ol>
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">3.4 Limits</h4>
              <p className="mb-20">
                Tich reserves the right to limit your use of the Services, including your ability to 
                contact other Members. Tich reserves the right to restrict, suspend, or terminate your 
                account if you breach this Agreement or the law or are misusing the Services (e.g., violating any of the Dos and Don’ts).
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">3.5 Intellectual Property Rights</h4>
              <p className="mb-20">
                Tich reserves all of its intellectual property rights in the Services. Trademarks 
                and logos used in connection with the Services are the trademarks of their respective owners. 
                Tich, and “in” logos and other Tich trademarks, service marks, graphics and logos 
                used for our Services are trademarks or registered trademarks of Tich.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">3.6 Automated Processing</h4>
              <p className="mb-20">
                We use the information and data that you provide and that we have about 
                Members to make recommendations for connections, content and features that 
                may be useful to you. For example, we use data and information about you to 
                recommend jobs to you and you to employers. Keeping your profile accurate and up 
                to date helps us to make these recommendations more accurate and relevant.
              </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">4. Disclaimer and Limit of Liability</h3>
          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">4.1 No Warranty</h4>
              <p className="mb-20">
                Tich AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING 
                ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES 
                (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT 
                PERMITTED UNDER APPLICABLE LAW, Tich AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, 
                INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR 
                A PARTICULAR PURPOSE.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">4.2 Exclusion of Liability</h4>
              <p className="mb-20">
                TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS Tich HAS ENTERED INTO A SEPARATE WRITTEN 
                AGREEMENT THAT OVERRIDES THIS AGREEMENT), Tich, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE 
                IN CONNECTION WITH THIS AGREEMENT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION 
                (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES 
                TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
                Tich AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS AGREEMENT FOR ANY AMOUNT
                THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO Tich FOR THE SERVICES DURING THE TERM OF THIS AGREEMENT, IF ANY, OR (B) US $1000.
              </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">4.3 Basis of the Bargain; Exclusions</h4>
              <p className="mb-20">
                The limitations of liability in this Section 4 are part of the basis of the bargain 
                between you and Tich and shall apply to all claims of liability 
                (e.g., warranty, tort, negligence, Agreement and law) even if Tich or its 
                affiliates has been told of the possibility of any such damage, and even if these remedies 
                fail their essential purpose.
              </p>

              <p className="mb-20">
                These limitations of liability do not apply to liability for death or personal injury or for fraud, 
                gross negligence or intentional misconduct, or in cases of negligence where a material obligation has 
                been breached, a material obligation being such which forms a prerequisite to our delivery of services 
                and on which you may reasonably rely, but only to the extent that the damages were directly caused by 
                the breach and were foreseeable upon conclusion of this Agreement and to the extent that they are typical
                in the context of this Agreement .
              </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">4. Termination</h3>
          <div className="col-12 order-lg-3">
            <p className="mb-20">
              Both you and Tich may terminate this Agreement at any time with notice to 
              the other. On termination, you lose the right to access or use the Services. The following shall survive termination:
              <ul>
                <li>Our rights to use and disclose your feedback.</li>
                <li>Members and/or Visitors’ rights to further re-share content and information you shared through the Services.</li>
                <li>Sections 4, 6, 7, and 8.2 of this Agreement.</li>
                <li>Any amounts owed by either party prior to termination remain owed after termination.</li>
              </ul>
            </p>

            <p className="mb-20">
              You can visit your profile to close your account.
            </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">6. Governing Law and Dispute Resolution</h3>
          <div className="col-12 order-lg-3">
            <p className="mb-20">
              If you live in the Designated Countries, and are a Data Subject infer the General Data Protection 
              Regulations or any related regulations Tich shall where possible effect to regulations on 
              Personal Data. With respect to jurisdiction, you and Tich agree to choose the courts of the 
              country to which we direct your Services where you have habitual residence for all disputes arising out 
              of or relating to this User Agreement.
            </p>

            <p className="mb-20">
              For others outside of Designated Countries or any conflicts resulting to a dispute that does not involve 
              Members or Visitors Personal Data, for those living in Designated Countries: You and Tich agree 
              that the laws of the Republic of Kenya, excluding its conflict of laws rules, shall exclusively govern any 
              dispute relating to this Agreement and/or the Services. You and Tich both agree that all claims 
              and disputes can be litigated only in the of the Republic of Kenya, and you and Tich each agree to 
              personal jurisdiction in those courts.
            </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">7. General Terms</h3>
          <div className="col-12 order-lg-3">
            <p className="mb-20">
              If a court with authority over this Agreement finds any part of it unenforceable, you and we agree that 
              the court should modify the terms to make that part enforceable while still achieving its intent. If the 
              court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce 
              the rest of this Agreement.
            </p>

            <p className="mb-20">
              This Agreement (including additional terms that may be provided by us when you engage with a feature of the Services) 
              is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
            </p>

            <p className="mb-20">
              If we don't act to enforce a breach of this Agreement, that does not mean that Tich has waived its right to enforce 
              this Agreement You may not assign or transfer this Agreement (or your membership or use of Services) to anyone without our consent.
              However, you agree that Tich may assign this Agreement to its affiliates or a party that buys it without your consent. 
              There are no third-party beneficiaries to this Agreement.
            </p>

            <p className="mb-20">
              You agree that the only way to provide us legal notice is at the addresses provided in Section 10.
            </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">8.  Tich “Dos and Don’ts”</h3>
          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">8.1 Dos</h4>
            <p className="mb-20">
              You agree that you will:

              <ol>
                <li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements.</li>
                <li>Provide accurate information to us and keep it updated.</li>
                <li>Use your real name on your profile.</li>
                <li>Use the Services in a professional manner.</li>
              </ol>
            </p>
          </div>

          <div className="col-12 order-lg-3">
            <h4 className="mt-10 mb-20">8.2 Don’ts</h4>
            <p className="mb-20">
              You agree that you will not:

              <ol>
                <li>Create a false identity on Tich, misrepresent your identity, create a Member profile for anyone other than yourself (a real person), or use or attempt to use another account.</li>
                <li>Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins, and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services.</li>
                <li>Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views).</li>
                <li>Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Tich.</li>
                <li>Disclose information that you do not have the consent to disclose (such as confidential information of others, including your employer).</li>
                <li>Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license.</li>
                <li>Violate the intellectual property or other rights of Tich, including, without limitation:
                  <ol type="a">
                    <li>Copying or distributing our learning videos or other materials,</li>
                    <li>Copying or distributing our technology, unless it is released under open source licenses,</li>
                    <li>Using the word “Tich” or our logos in any business name, email, or URL.</li>
                  </ol>
                </li>
                <li>Post anything that contains software viruses, worms, or any other harmful code.</li>
                <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source.</li>
                <li>Imply or state that you are affiliated with or endorsed by Tich without our express consent (e.g., representing yourself as an accredited Tich trainer).</li>
                <li>Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without Tich’s consent.</li>
                <li>Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without Tich’s consent.</li>
                <li>Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages.</li>
                <li>Monitor the Services’ availability, performance, or functionality for any competitive purpose.</li>
                <li>Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services.</li>
                <li>Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services).</li>
                <li>Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms).</li>
                <li>Violate any additional terms concerning a specific Service that are provided when you sign up for or start using such Service.</li>
                <li>Complaints Regarding Content: we respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. If you have a complaint about any of the content posted on any of the Services please contact our help center under Section 10.</li>
                <li>How To Contact Us: For general inquiries, you may contact us online. For legal notices or service of process, you may write us at these addresses.</li>
              </ol>
            </p>
          </div>
        </div>

        <div className="row gx-30">
          <h3 className="page-title mt-30">9. General Terms</h3>
          <div className="col-12 order-lg-3">
            <p className="mb-20">
              We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. If you have a complaint about any of the content posted on any of the Services please contact our help center under Section 10.
            </p>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default TermsOfUseContent