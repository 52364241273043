import axios from "axios";
import API from "../helper/api.config";
import { catchError } from "../helper/validation";

export const sendMessage = (values) => {
  return axios
    .post(API.getApi().CONTACT.SEND_MSG, values)
    .then(async ({ data }) => {
      return data;
    })
    .catch(async (error) => {
      catchError(error, '');
    });
};
