import React from "react";
import { Link } from "react-router-dom";

const ServiceOne = () => {
  return (
    <div className="service-area-1 space-bottom overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <div className="title-area text-center">
              <img src="assets/img/icon/title_left.svg" alt="shape" />
              <h2 className="sec-title style2">
                Our Services
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gx-30 gy-30 justify-content-center">
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-card_img">
                <img src="assets/img/service/service-1-2.svg" alt="img" />
              </div>
              <div className="service-card_content">
                <h4 className="service-card_title">
                  <Link to="#">Skilling</Link>
                </h4>
                <p className="service-card_text">
                  AI enabled on-demand curated training per user
                </p>
                <Link to="/service" className="link-btn">
                  Read More <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="service-card">
              <div className="service-card_img">
                <img src="assets/img/service/service-1-1.svg" alt="img" />
              </div>
              <div className="service-card_content">
                <h4 className="service-card_title">
                  <Link to="#">Gig CV</Link>
                </h4>
                <p className="service-card_text">
                  Consolidation of online reputation from various platforms on one document.
                </p>
                <Link to="/service" className="link-btn">
                  Read More <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="service-card">
              <div className="service-card_img">
                <img src="assets/img/service/service-1-3.svg" alt="img" />
              </div>
              <div className="service-card_content">
                <h4 className="service-card_title">
                  <Link to="#">Digital lending</Link>
                </h4>
                <p className="service-card_text">
                  Gig-work force access to credit
                </p>
                <Link to="/service" className="link-btn">
                  Read More <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="service-card">
              <div className="service-card_img">
                <img src="assets/img/service/service-1-4.svg" alt="img" />
              </div>
              <div className="service-card_content">
                <h4 className="service-card_title">
                  <Link to="#">Payments</Link>
                </h4>
                <p className="service-card_text">
                  Hire, manage, pay employees on one platform.
                </p>
                <Link to="/service" className="link-btn">
                  Read More <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOne;
