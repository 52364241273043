import React from "react";
import { Helmet } from "react-helmet";
const HelmetReact = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title} || TICH - Be Found
      </title>
      <meta
        name="Connecting jobseekers and employers daily..."
        content="TICH - Be Found"
      />
    </Helmet>
  );
};

export default HelmetReact;
