import React from "react";
import { Link } from "react-router-dom";

const AboutOne = () => {
  return (
    <div className="about-area-1 position-relative space-top">
      <div className="about1-shape-img1">
        <img
          className="about1-shape-img-1"
          src="assets/img/normal/about-bg2.svg"
          alt="img"
        />
      </div>
      <div className="about1-shape-img2">
        <img
          className="about1-shape-img-2"
          src="assets/img/normal/about_shape1-2.png"
          alt="img"
        />
      </div>
      <div className="container">
        <div className="row gx-60 align-items-center">
          <div className="col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area me-xl-5 mb-20">
                <img src="assets/img/icon/title_left.svg" alt="shape" />
                <h2 className="sec-title">About Tich</h2>
              </div>
              <div className="achive-about">
                <div className="achive-about_content">
                  <div className="achive-about_icon">
                    <i className="fas fa-check" />
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">Our vision</h3>
                    <p className="achive-about_text">
                      Continuously innovate and adapt, anticipate the needs of the future African workforce and provide a 
                      job matching platform that remains at the forefront of career development, creating a global community of 
                      networks whilst elevating the communities in which we operate in through automated job matching, skill development and 
                      the necessary tech enabled solutions to foster economic development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="achive-about">
                <div className="achive-about_content">
                  <div className="achive-about_icon">
                    <i className="fas fa-check" />
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">
                      Our Mission
                    </h3>
                    <p className="achive-about_text">
                      We empower individuals by connecting them with meaningful and fulfilling employment opportunities. We are committed to 
                      leveraging cutting-edge technology and data-driven insights to streamline the job search process, ensuring that job seekers 
                      find positions that align with their skills, interests, and aspirations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
