import React from "react";
import { Link } from "react-router-dom";
import { employerUrls, jobseekerUrls } from "../helper/urls";

const HeroOne = () => {
  return (
    <div
      className="hero-wrapper bg-smoke hero-1"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/hero_bg_1_1.png)" }}
    >
      <div className="container">
        <div className="row align-items-end">
          <div className="col-xl-6">
            <div className="hero-style1">
              <h1 className="hero-title">
               Connecting jobseekers and employers daily...
              </h1>
              <div className="btn-group">
                <Link to={jobseekerUrls.devUrl} className="global-btn" target="_blank">
                  Join as Jobseeker<img src="assets/img/icon/right-icon.svg" alt="TICH" />
                </Link>
                <Link to={employerUrls.devUrl} className="global-btn style-border" target="_blank">
                  Join as Employer
                </Link>
              </div>
              <p className="hero-text">
                First platform in Africa to provide end to end solutions for the world of work. <br />
                The super platform has AI enabled jobs matching, tech-enabled on demand skilling, integrated payments and digital lending for gig workers
              </p>
            </div>
          </div>
          <div className="col-xl-6 mb-5">
            <div className="hero-image-wrapp">
              <div className="hero-thumb text-center">
                <img src="assets/img/hero/hero-1.svg" alt="img" />
              </div>
              <div className="hero-shape1" />
              <div className="hero-shape2" />
              <div className="hero-shape3" />
              <div className="hero-shape4" />
              <div className="hero-shape5 spin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroOne;
